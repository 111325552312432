import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"

const PoliticaDeConfidentialitate = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Politica de confidențialitate"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Politica de confidențialitate"
      />
      <section className="privacy-policy-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="privacy-policy-content">
                <h3>SiteBunker este un furnizor de servicii web ce primește și stochează date personale ale clienților
                  în conformitate cu prevederile UE.</h3>

                <p>Această declarație de confidențialitate este o politica de confidențialitate completă aplicabilă
                  activităților online ale Sitebunker.ro. Această declarație detaliază tipurile de informații pe care le
                  colectăm de la clienți și modul în care utilizăm aceste informații.</p>

                <p> Website-ul nostru este în conformitate cu Regulamentul General de Protecție a Datelor (“GDPR”),
                  (Regulament (EU) 2016/679), care este un act legislativ obligatoriu. Politica de protejare a datelor
                  în SiteBunker se bazează pe următoarele principii de protecție a datelor:</p>
                <ul>
                  <li>Procesarea datelor personale se va face in mod legal, corect si transparent.</li>
                  <li>Colectarea datelor personale se va face numai în scopuri specificate și datele nu vor fi procesate
                    mai departe într-o manieră incorectă față de acele scopuri;
                  </li>
                  <li>Colectarea datelor personale va fi adecvată, relevantă și limitată la informațiile necesare
                    scopului procesării;
                  </li>
                  <li>Datele personale vor fi autentice și, acolo unde este necesar, actualizate;</li>
                  <li>Se vor lua toate măsurile necesare pentru a se asigura că datele incorecte sunt șterse sau
                    corectate imediat;
                  </li>
                  <li>Datele personale vor fi păstrate într-o formă ce permite identificarea persoanei vizate și pentru
                    o perioadă nu mai lungă decât cea în care datele personale sunt procesate;
                  </li>
                  <li>Toate datele personale vor fi păstrate confidențial și stocate într-o manieră sigură;</li>
                  <li>Datele personale nu vor fi distribuite persoanelor terțe decât în cazul în care este necesar în
                    scopul oferirii de servicii conform acordurilor;
                  </li>
                  <li>Persoanele vizate au dreptul de a solicita accesul la datele personale, rectificarea și ștergerea
                    acestora, împotrivirea sau restricționarea de la procesarea datelor atât cât și de la dreptul
                    portabilității datelor.
                  </li>
                </ul>

                <h3>In ce scop prelucram datele tale personale?</h3>
                <p>Colectam informatiile tale în scopuri determinate și legitime care includ, dar nu se limiteaza la
                  urmatoarele:</p>
                <ul>
                  <li>În vederea incheierii sau executarii unui contract dintre tine și noi;</li>
                  <li>Pentru a-ti raspunde la intrebari și solicitari;</li>
                  <li>In scop de marketing</li>
                  <li>Pentru a oferi și imbunatati serviciile pe care le oferim;</li>
                  <li>In scop de marketing</li>
                  <li>Pentru a diagnostica sau a remedia probleme tehnice;</li>
                </ul>

                <h3>Utilizarea Cookie-urilor</h3>
                <p>Website-ul SiteBunker și partenerii săi utilizează cookie-uri sau tehnologii similare pentru a
                  asigura cea mai bună experiență utilizatorilor și pentru a analiza trenduri, administra website-ul,
                  urmări utilizarea de către client în cadrul website-ului și pentru a colecta informații demografice
                  despre baza de utilizatori ca și întreg. Cookie-urile sunt mici fișiere text care sunt plasate pe
                  dispozitivul clientului pentru a urmări modul de utilizare și a înregistra preferințele.</p>
                <p>Menționăm și că acest site utilizeaza Google Analytics, un serviciu de analiza web furnizat de
                  Google, Inc. (“Google”).</p>


                <h3>Accesul și rectificarea sau ștergerea datelor personale</h3>
                <p>Clienții au dreptul de a solicita în orice moment accesul la rectificarea, ștergerea sau
                  restricționarea procesării datelor colectate de către noi. Pentru a ne ajuta să păstrăm datele
                  personale actualizate, recomandăm utilizatorilor să ne informeze despre orice schimbare sau
                  discrepanță. Pentru a vizualiza sau modifica datele personale, pentru a obține informații despre
                  perioada de timp pentru care Sitebunker intenționează să păstreze datele personale sau pentru alte
                  întrebări legate de datele personale sau dacă doriți să solicitați vă informăm dacă, la solicitarea
                  unui terț, am stocat sau am prelucrat oricare din datele dvs. cu caracter personal, vă rugăm să ne
                  trimiteți un mail.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default PoliticaDeConfidentialitate
