import React from "react"

import "../../assets/css/Common/PageBanner.style.css"

const PageBanner = ({ pageTitle, pageDescription, underDescription }) => {
  return (
    <div className="page-title-area">
      <div className="container">
        <div className="page-title-content">
          <h1 className="mb-4 h1-title">{pageTitle}</h1>
          <div className="row">
            <div className="col-md-9 col-12">
              {pageDescription !== undefined ? <p>{pageDescription}</p> : ""}
              {underDescription !== undefined ? <p className="font-weight-normal">{underDescription}</p> : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageBanner